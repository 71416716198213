import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueSmoothScroll from 'vue-smooth-scroll'
import VueTagManager from "vue-tag-manager"
import VueGtag from "vue-gtag"

Vue.use(vueSmoothScroll)
Vue.config.productionTip = false

Vue.use(VueTagManager, {
  gtmId: 'GTM-N59R987'
})
Vue.use(VueGtag, {
  config: { 
    id: "UA-167786690-1",
    params: {
      send_page_view: false
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
