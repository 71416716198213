<template>
  <div>
    <!-- root -->
    <WaBtn />
    <LanguageSelect />
    <!-- header -->
    <SiteHeader />
    <!-- end header -->
    <!-- main banner -->

    <div
      class="bg-center bg-no-repeat bg-cover bg-adjust"
      style="background-image: url('image/banner-main.jpg')"
    >
      <div class="container flex">
        <div class="block md:w-1/4 lg:w-2/3"></div>

        <div class="w-full md:w-3/4">
          <div class="px-5 py-20 md:py-24 lg:py-32">
            <div class="px-4 py-4 text-center box">
              <h1
                class="text-3xl font-bold leading-tight text-white md:text-4xl"
              >
                Why Go Through All The Hassle Just To Fix Your Windshield?
              </h1>

              <div
                class="pt-4 font-normal text-white text-md md:text-xl md:leading-tight lg:text-3xl"
              >
                Let us come to you and get the job done while you sit back and
                relax!
              </div>

              <div class="pt-6 pb-4">
                <a href="tel:+60143387288" class="block w-55">
                  <div class="w-auto py-2 mx-auto bg-yellow-400 rounded-lg">
                    <div
                      class="font-semibold texl-lg md:text-xl text-black-300"
                    >
                      Fix Your Appointment
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end main banner -->

    <!-- about us -->

    <div class="section">
      <div class="container">
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <div>
            <img
              src="/image/img-about.png"
              alt="Grand Auto Windshield"
              class="w-2/3 mx-auto md:w-1/2 lg:w-2/3 lg:pt-10 xl:pt-6"
            />
          </div>

          <div class="text-center lg:text-left">
            <h1 class="text-2xl font-semibold text-yellow-500 md:text-3xl">
              About Us
            </h1>

            <p class="py-5 font-medium text-gray-800 text-md">
              <b>GRAND AUTO WINDSCREEN SDN BHD</b> is the Malaysia leading
              mobile vehicle windscreen replacement specialist, we provide
              door-to-door windscreen replacement service and more than 10
              years’ experience.

              <br />

              <br />One of our mobile technicians can come to you at home, at
              work or wherever you are located, you can relax in the knowledge
              that all <b>GRAND AUTO WINDSCREEN SDN BHD</b> workmanship is
              guaranteed for as long as you own the vehicle. What’s more, as the
              Malaysia leading mobile vehicle windscreen replacement company, we
              have strong relationships with most of the Malaysia major
              insurance companies enabling us to take care of your insurance
              claim for you if you are claiming for the work on your vehicle
              insurance.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-center bg-no-repeat bg-cover section"
      style="background-image: url('image/banner-core-2.jpg')"
    >
      <div class="container">
        <div class>
          <div class="text-center">
            <h1 class="text-2xl font-semibold text-white md:text-3xl">
              Our Core Services
            </h1>

            <div
              class="py-6 font-semibold text-center text-white lg:max-w-4xl lg:mx-auto md:flex md:flex-wrap text-md lg:text-lg"
            >
              <div
                v-for="list in services"
                :key="list"
                class="flex items-center justify-center pb-3 text-center text-white md:justify-start md:w-1/2 lg:w-1/3"
              >
                <i class="pr-2 fas fa-check"></i>
                <h1>{{ list }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end about us -->

    <!-- product and services -->

    <div class="section">
      <div class="container">
        <h1
          class="pb-4 text-xl font-semibold text-center text-yellow-500 md:text-2xl lg:text-3xl"
        >
          Door to Door Windscreen Service
        </h1>

        <img src="/image/door-to-door.png" alt class="pb-6" />

        <div class="flex flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in windscreen"
            :key="i"
            class="w-1/2 md:w-1/3 lg:w-1/3"
          >
            <img
              :src="item.image"
              :alt="item.alt"
              class="w-full p-1 xl:w-auto"
            />
          </div>
        </div>

        <h1
          class="pt-4 text-xl font-semibold text-center text-yellow-500 md:text-2xl lg:text-3xl"
        >
          Product Services
        </h1>

        <div class="text-center">
          <img src="/image/banner-product.jpg" class="mx-auto" />

          <p class="py-2 font-bold text-gray-800 lg:text-lg text-md">
            From head to toe, if it is glass related, we got you covered! We
            provide a full range of automotive glass product services.
          </p>
        </div>
      </div>
    </div>

    <div class="bg-yellow-500">
      <div class="container">
        <div class="flex flex-wrap py-3 lg:py-5">
          <!-- <div class="w-full pb-4 lg:w-1/3 xl:w-1/2"></div> -->

          <div class="w-full px-3">
            <h1
              class="py-2 text-lg font-semibold leading-tight text-center text-black md:text-xl lg:text-2xl"
            >
              Welcome To Our Grand Shop, We Have A Comfortable Environment &
              Waiting Area <br class="hidden md:block"> Ready To Serve You & Your Precious Car
            </h1>

            <div class="grid grid-cols-2 pt-3 md:grid-cols-3">
              <div class v-for="(item, i) in ourstore" :key="i">
                <img
                  :src="item.image"
                  :alt="item.alt"
                  class="p-1 lg:w-full xl:w-auto"
                />
              </div>
            </div>

            <p class="text-center">
              <b>Address:</b>

              <br />No. 24G, Persiaran Zaaba, Taman Tun Dr Ismail, <br />
              60000 Kuala Lumpur.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Windscreen Repair  -->

    <div class="py-4">
      <div class="container">
        <h1
          class="pb-4 text-xl font-semibold text-center text-yellow-500 md:text-2xl lg:text-3xl"
        >
          Windscreen Repair
        </h1>

        

        <div class="grid grid-cols-2 pb-4 lg:grid-cols-4">
          <div v-for="image in glass_repair" :key="image">
            <img :src="image" alt="" class="w-full p-1" />
          </div>
        </div>

        <div class="">
          <h1 class="text-lg font-semibold text-center text-black lg:text-xl">
            Your Windscreen Will Be As Good As New!
          </h1>
          <div class="grid grid-cols-2">
            <div class="p-1">
              <img src="/image/windscreen-5.jpg" alt="" class="lg:w-full" />
              <h1
                class="py-2 text-lg font-semibold text-center text-black lg:text-xl"
              >
                Before
              </h1>
            </div>
            <div class="p-1">
              <img src="/image/windscreen-6.jpg" alt="" class="lg:w-full" />
              <h1
                class="py-2 text-lg font-semibold text-center text-black lg:text-xl"
              >
                After
              </h1>
            </div>
          </div>
        </div>

        <!-- cta button -->
        <div class="pt-3 lg:pt-1">
          <a
            href="tel:+60143387288"
            class="block w-40 mx-auto transition duration-300 ease-linear bg-yellow-500 rounded-md hover:bg-yellow-600"
          >
            <h1 class="py-2 text-lg text-center">Call Us Now</h1>
          </a>
        </div>
      </div>
    </div>

    <div class="py-4 mb-10">
      <div class="container">
        <h1
          class="pb-4 text-xl font-semibold text-center text-yellow-500 md:text-2xl lg:text-3xl"
        >
          Tinted Installation
        </h1>

        <div class="grid grid-cols-2 xl:grid-cols-4">
          <div class v-for="(item, i) in tinted" :key="i">
            <img
              :src="item.image"
              :alt="item.alt"
              class="p-1 lg:w-full xl:w-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Insurance Panel -->
    <!-- <div class="py-5 bg-gray-400">
      <div class="container"></div>
    </div> -->

    <!-- why choose us -->
    <div class="bg-gray-400 section">
      <div class="container">
        <div>
          <h1
            class="pb-4 text-2xl font-semibold text-center text-black md:text-3xl lg:pb-8"
          >
            Why Choose Us
          </h1>
        </div>

        <div class="grid grid-cols-1 text-center md:grid-cols-4">
          <div v-for="(item, i) in ChooseUs" :key="i">
            <img :src="item.image" :alt="item.alt" class="mx-auto" />

            <!-- <p class="text-lg font-semibold text-black">{{item.alt}}</p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- end why choose us -->

    <!-- Gallery -->

    <div class="py-5">
      <div class="container">
        <div class="py-3">
          <h1
            class="text-xl font-semibold text-center text-yellow-500 md:text-3xl"
          >
            Our Crew in Action
          </h1>
        </div>

        <div class="flex flex-wrap lg:justify-center">
          <div
            class="w-1/2 px-1 md:w-1/3"
            v-for="(item, i) in gallery"
            :key="i"
          >
            <img :src="item.image" :alt="item.alt" class="py-1" />
          </div>
        </div>
      </div>
    </div>

    <!-- end Gallery -->

    <!-- our process -->

    <div class>
      <div class="container">
        <div class="pb-3">
          <h1
            class="text-xl font-semibold text-center text-yellow-500 md:text-3xl"
          >
            Our Process
          </h1>
        </div>

        <div>
          <img src="/image/our-process.jpg" alt class="rounded-lg" />
        </div>

        <div class="pt-10 pb-5">
          <h2
            class="pb-4 text-xl font-semibold text-center text-yellow-500 md:text-2xl lg:text-3xl"
          >
            Our Insurance Panel
          </h2>
          <img src="/image/logo-d.jpg" class="hidden w-full mx-auto lg:block" />
          <img src="/image/logo-m.jpg" class="block w-full mx-auto lg:hidden" />
        </div>
      </div>
    </div>

    <!-- end process -->

    <!-- Enquiry -->

    <div
      id="enquiry"
      class="bg-center bg-no-repeat bg-cover section"
      style="background-image: url('image/banner-core.jpg')"
    >
      <div class="container">
        <div class="flex flex-wrap">
          <div class="lg:w-1/2"></div>

          <div class="w-full enquire-box lg:w-1/2">
            <h1 class="py-4 font-semibold text-center text-white md:text-2xl">
              Fill the enquiry form
            </h1>

            <!-- feedback.activamedia.com.sg script begins here -->

            <iframe
              allowtransparency="true"
              style="min-height: 550px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="//feedback.activamedia.com.sg/my-contact-form-5323080.html"
            >
              <p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p>
            </iframe>

            <!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>

    <!-- end Enquiry -->

    <!-- footer -->

    <SiteFooter />

    <SiteCopyright />

    <!-- end footer -->

    <!--end root    -->
  </div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import SiteCopyright from "@/components/SiteCopyright";
import WaBtn from "@/components/WaBtn";
import LanguageSelect from "@/components/LanguageSelect.vue";

export default {
  name: "Home",
  components: {
    SiteHeader,
    SiteFooter,
    SiteCopyright,
    WaBtn,
    LanguageSelect,
  },
  methods: {
    login() {
      this.$gtag.event("login", { method: "Google" });
    },
  },
  data() {
    return {
      services: [
        "Windscreen Installation",
        "Windscreen Insurance Claim ",
        "Windscreen Repair ",
        "Door-to-door Services",
        "Tinted Film Installation",
        "Motor Insurance & Road Tax Renewal",
      ],
      windscreen: [
        { image: "/image/door_to_door-1.jpg", alt: "" },
        { image: "/image/door_to_door-2.jpg", alt: "" },
        { image: "/image/door_to_door-3.jpg", alt: "" },
        { image: "/image/door_to_door-4.jpg", alt: "" },
        { image: "/image/door_to_door-5.jpg", alt: "" },
        { image: "/image/door_to_door-6.jpg", alt: "" },
      ],
      ourstore: [
        { image: "/image/store-1.jpg", alt: "Come to our store image" },
        { image: "/image/store-2.jpg", alt: "Come to our store image" },
        { image: "/image/store-3.jpg", alt: "Come to our store image" },
        { image: "/image/store-4.jpg", alt: "Come to our store image" },
        { image: "/image/store-5.jpg", alt: "Come to our store image" },
        { image: "/image/store-6.jpg", alt: "Come to our store image" },
      ],
      glass_repair: [
        "/image/windscreen-1.jpg",
        "/image/windscreen-3.jpg",
        "/image/windscreen-2.jpg",
        "/image/windscreen-4.jpg",
      ],
      tinted: [
        { image: "/image/tinted-1.jpg", alt: "Pic 1" },
        { image: "/image/tinted-2.jpg", alt: "Pic 2" },
        { image: "/image/tinted-3.jpg", alt: "Pic 3" },
        { image: "/image/tinted-4.jpg", alt: "Pic 4" },
      ],
      ChooseUs: [
        { image: "/image/icon-1.png", alt: "Door To Door Windscreen" },
        { image: "/image/icon-2.png", alt: "Fast & Efficient" },
        { image: "/image/icon-3.png", alt: "Exceptional Workmanship" },
        { image: "/image/icon-4.png", alt: "5 Years Warranty" },
      ],
      gallery: [
        { image: "/image/crew-1.jpg", alt: "crew in action" },
        { image: "/image/crew-2.jpg", alt: "crew in action" },
        { image: "/image/crew-3.jpg", alt: "crew in action" },
        { image: "/image/crew-4.jpg", alt: "crew in action" },
        { image: "/image/crew-5.jpg", alt: "crew in action" },
        { image: "/image/crew-6.jpg", alt: "crew in action" },
      ],
    };
  },
};
</script>

<style>
@media (min-width: 1024px) {
  .bg-adjust {
    background-position-y: -160px;
  }
}
</style>
